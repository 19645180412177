import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const HeroWrapper = tw.div`
  mt-[84px] pt-[60px] lg:pt-[40px] text-base_text flex flex-col justify-center lg:justify-end items-start text-start px-[30px] lg:px-0
`;

const Heading = tw.h1`
   text-[36px] !font-[600] text-base_text lg:text-[48px] lg:!font-[500] mb-4 lg:mb-[64px] 
`;

const DateTag = tw.div`
  text-[14px] font-[500] border border-[#E4EEFB] bg-[#E4EEFB] rounded-full py-1 px-4 text-accent_blue_2 mb-4
`;

const PrivacyHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiPrivacyPolicy {
        nodes {
          PageTag
          PageHeader
        }
      }
    }
  `);
  const { PageTag, PageHeader } = data.allStrapiPrivacyPolicy.nodes[0];
  return (
    <HeroWrapper>
      <DateTag>{PageTag}</DateTag>
      <Heading>{PageHeader}</Heading>
    </HeroWrapper>
  );
};

export default PrivacyHero;
