import * as React from 'react';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql } from 'gatsby';
import PrivacyPage from 'components/pages/privacy';
import NewLayout from 'components/NewLayout';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/privacy-policy`;

const PrivacyPageIndex = () => {
  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="light">
        <BreadcrumbList
          pageLabelId="structuredData.privacyPolicy"
          pageUrl={pageUrl}
        />
        <PrivacyPage />
      </NewLayout>
    </LanguageProvider>
  );
};
export default PrivacyPageIndex;

export const Head = ({
  data,
}: {
  data: {
    allStrapiPrivacyPolicy: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiPrivacyPolicy.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiPrivacyPolicy {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
