import React, { useEffect } from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import PrivacyHero from './PrivacyHero';
import LayoutContainer from 'components/LayoutContainer';
import { graphql, useStaticQuery } from 'gatsby';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const queryData = useStaticQuery(graphql`
    query {
      allStrapiPrivacyPolicy {
        nodes {
          PageDescription
          bottomBoxHeading
          bottomBoxDescription
          bottomBoxButtonLabel
          bottomBoxButtonLink
          details {
            description {
              data {
                description
              }
            }
            title
            id
          }
        }
      }
    }
  `);
  const {
    PageDescription,
    details,
    bottomBoxHeading,
    bottomBoxDescription,
    bottomBoxButtonLabel,
    bottomBoxButtonLink,
  } = queryData.allStrapiPrivacyPolicy.nodes[0];
  return (
    <LanguageProvider messages={translation}>
      <div className="bg-accent_grey_1">
        <LayoutContainer className="lg:pb-[60px]">
          <PrivacyHero />
          <TermsAndConditions
            details={details}
            pageDescription={PageDescription}
            bottomBoxHeading={bottomBoxHeading}
            bottomBoxDescription={bottomBoxDescription}
            bottomBoxButtonLabel={bottomBoxButtonLabel}
            bottomBoxButtonLink={bottomBoxButtonLink}
          />
        </LayoutContainer>
      </div>
    </LanguageProvider>
  );
};

export default PrivacyPage;
